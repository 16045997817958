body {
  /* font-family: monospace !important; */
  background-color: #414159 !important;
  max-width: 100vw;
  overflow-x: hidden;
}
div.columns {
  max-width: 100vw;
}
textarea {
  border: 0;
  min-height: 170px;
  max-height: 170px;
  max-width: 95%;
  display: flex;
  background: transparent;
  box-sizing: border-box;
  /* font-family: monospace !important; */
  padding: 0;
  flex: 1;
  align-items: center;
  text-align: center;
  outline: none;
  font-size: 17px;
  line-height: 1.5em;
}
#notif-content {
  padding: 20px 0 10px 0;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 0px solid #ddd;
  border-radius: 4px;
  background-color: #fefefe;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.3);
  margin-bottom: 4px;
}
.hero {
  background: transparent !important;
}
.notification {
  /* background-color: #fefefe !important; */
  background-color: transparent !important;
  padding: 1.25rem !important;
}

.result {
  border-radius: 4px;
  margin: 8px auto;
  padding: 0.3rem !important;
  position: relative;
  overflow: hidden;
  font-size: 13px;
  box-shadow: 0 0px 1px rgba(255, 255, 255, 0.4);
  background-color: rgba(255, 255, 255, 1);
}

.result span.result-bar {
  position: absolute;
  left: 0;
  top: 0;
  height: 120%;
  z-index: 0;
}
.result .result-value {
  position: relative;
  z-index: 10;
  color: #444;
  text-shadow: 0 0px 3px rgba(255, 255, 255, 0.9);
  font-size: 16px;
}
.kristy .result-bar {
  background-color: #ffe7d1;
}
.gurman .result-bar {
  background-color: #efa8e4;
}
.nikayla .result-bar {
  background-color: #d4ebd0;
}
.frankie .result-bar {
  background-color: #beebe9;
}
.tori .result-bar {
  background-color: #dcd6f7;
}
.troy .result-bar {
  background-color: #eff3c6;
}
.susie .result-bar {
  background-color: #f1d1d1;
}
.cristina .result-bar {
  background-color: #d6e5fa;
}
.mariah .result-bar {
  background-color: #f6d198;
}

.loading-spinner {
  animation-play-state: running;
  opacity: 1;
  position: relative;
  height: 50px;
}

@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

.loading-spinner.-paused {
  animation-play-state: paused;
  opacity: 0.2;
  transition: opacity linear 0.2s;
}

.loading-spinner::before {
  animation: 0.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 1.5px #dedede;
  border-bottom-color: #414159;
  border-radius: 50%;
  content: "";
  height: 30px;
  left: 50%;
  opacity: inherit;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 30px;
  will-change: transform;
}
button {
  font-size: 14px !important;
}
#predict-button {
  margin-top: -20px;
}
@media screen and (max-width: 450px) {
  .notification {
    padding: 0 !important;
  }
  #predict-button {
    margin-top: -8px;
  }
}
